<template>
  <div class="w-1200 bg-white mt-2">
    <div class="w-100 bg-main-color" style="height: 2px;"></div>
    <hot-recommend :hotGoodsList="hotGoodsList"></hot-recommend>
    <div class="mt-2 w-100">
      <div style="width: 1000px;float: left;" class="none"
           v-if="searchGoodsList.length<=0">暂无信息
      </div>
      <div style="width: 1000px;float: left;" v-else class="mb-5">
        <!-- 左边列表 -->
        <common-goods-list :goodsList="searchGoodsList"></common-goods-list>
        <!-- 分页区域 -->
        <el-pagination class="mb-2"
          :current-page="queryInfo.page"
          :page-sizes="[20, 50, 100]"
          :page-size="queryInfo.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"/>
      </div>
      <!-- 右边相关推荐 -->
      <right-goods-list :goodsList="recommendList"></right-goods-list>
    </div>
  </div>
</template>

<script>
import hotRecommend from '@/components/productList/HotRecommend.vue'
import commonGoodsList from '@/components/productList/CommonGoodsList.vue'
import rightGoodsList from '@/components/productList/RightGoodsList.vue'
import { productSearchUrl, getHotGoods as getHotGoodsUrl, recommendGoods as getRecommendGoodsUrl } from '@/api/index'

export default {
  name: 'SearchGoods',
  components: {
    hotRecommend,
    commonGoodsList,
    rightGoodsList
  },
  data () {
    return {
      queryInfo: {
        pageSize: 20,
        page: 1,
        keyword: ''
      },
      hotGoodsList: [],
      searchGoodsList: [],
      recommendList: [],
      total: 0
    }
  },
  created () {
    this.initData()
  },
  methods: {
    initData () {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.getSearchGoodsList()
      this.getHotGoods()
      this.getRecommendGoods()
      setTimeout(()=>{
        this.changeLoading(loading)
      },300)
    },
    // 关闭loading弹窗
    changeLoading(loading){
      loading.close();
    },
    // 获取商品查询列表
    async getSearchGoodsList () {
      this.queryInfo.keyword = this.$route.query.keyword
      const { data: res } = await this.$http.get(productSearchUrl, { params: this.queryInfo })
      if (res.code !== 200) return this.$message.error(res.msg)
      console.log(res)
      this.searchGoodsList = res.data.list
      this.total = res.data.total
    },
    // 顶部热门推荐
    async getHotGoods () {
      const { data: res } = await this.$http.get(getHotGoodsUrl)
      if (res.code !== 200) return this.$message.error(res.msg)
      console.log(res)
      this.hotGoodsList = res.data.list
    },
    // 右侧推荐商品
    async getRecommendGoods () {
      const { data: res } = await this.$http.get(getRecommendGoodsUrl)
      if (res.code !== 200) return this.$message.error(res.msg)
      this.recommendList = res.data.list
    },
    // 分页页码显示条数
    handleSizeChange (newSzie) {
      // 把每页显示多少数据重新赋值
      this.queryInfo.pageSize = newSzie
      this.searchGoodsList = []
      // 重新获取数据并显示
      this.getSearchGoodsList()
    },
    // 页面切换事件
    handleCurrentChange (newPage) {
      this.queryInfo.page = newPage
      this.searchGoodsList = []
      // 重新获取数据并显示
      this.getSearchGoodsList()
    },
  },
  watch: {
    '$route': {
      handler () {
        this.initData()
      },
      deep: true,
      immediate: true,
    }
  },
}
</script>

<style scoped>

</style>
